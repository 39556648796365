import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{staticClass:"ml-auto",attrs:{"sm":"12","md":"2"}},[_c(VTextField,{attrs:{"prepend-inner-icon":"mdi-file-document-box-search","label":"Search","hint":"Registrant ID, License Number, Project Number, Audit Status, "},model:{value:(_vm.adhocText),callback:function ($$v) {_vm.adhocText=$$v},expression:"adhocText"}})],1),_c(VCol,{attrs:{"sm":"12","md":"3"}},[_c(VAutocomplete,{attrs:{"items":_vm.headers,"item-text":"text","item-value":"value","label":"Select Columns","chips":"","deletable-chips":"","small-chips":"","clearable":"","multiple":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 5)?_c(VChip,{attrs:{"small":"","close":""},on:{"click:close":function($event){return _vm.removeSelectedField(index)}}},[_vm._v(" "+_vm._s(item.text))]):_vm._e(),(index === 5)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.adhocSelectedFields.length - 5)+" others)")]):_vm._e()]}},{key:"prepend",fn:function(){return [_c('reorderSelectedFields',{model:{value:(_vm.adhocSelectedFields),callback:function ($$v) {_vm.adhocSelectedFields=$$v},expression:"adhocSelectedFields"}})]},proxy:true}]),model:{value:(_vm.adhocSelectedFields),callback:function ($$v) {_vm.adhocSelectedFields=$$v},expression:"adhocSelectedFields"}})],1)],1),(_vm.filters)?_c(VRow):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }