<template>
  <v-container fluid>
    <AuditInitiate></AuditInitiate>
    <AuditChange></AuditChange>
    <AuditReturn></AuditReturn>
  </v-container>
</template>
<script>
import AuditChange from '@components/reports/dor_interface/dOR_AuditChangeTransaction-adhoc-grid'
import AuditInitiate from '@components/reports/dor_interface/dOR_InitiateAuditTransaction-adhoc-grid'
import AuditReturn from '@components/reports/dor_interface/dOR_AuditReturnTransaction-adhoc-grid'
// doa_enterprise\shell_ui\src\components\reports\dor_interface\dOR_AuditChangeTransaction-adhoc-grid.vue
export default {
  //model: {
  //	prop: ''
  //},
  page: {
    title: 'DOR Interface Report',
  },
  props: {},
  components: {
    AuditChange,
    AuditInitiate,
    AuditReturn,
  },
  filters: {},
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
