<template>
  <BasePanelWithHeader headerText="Audit Finals">
    <BaseServerSyncDataTable
      :headers="filteredHeaders"
      :items="adhocDOR_AuditReturnTransactions"
      :searchTotal="adhocTotal"
      :loading="adhocLoading"
      :searchSortBy.sync="adhocSortBy"
      :searchDescending.sync="adhocDescending"
      :searchPage.sync="adhocPage"
      :searchRowsPerPage.sync="adhocRowsPerPage"
    >
      <!-- :show-group-by="!adhocPivotIncludePivot && showGroupBy"
      :groupBy.sync="groupBy"
      :groupDesc.sync="adhocGroupDescending" -->
      <template v-slot:searchParams>
        <DOR_AuditReturnTransactionParams
          :headers="headers"
        ></DOR_AuditReturnTransactionParams>
        <!-- <pivotOptions
          :includePivot.sync="adhocPivotIncludePivot"
          :pivotFirst.sync="adhocPivotFirst"
          :pivotName.sync="adhocPivotName"
          :rowFields.sync="adhocPivotRowFields"
          :columnFields.sync="adhocPivotColumnFields"
          :dataFields.sync="adhocPivotDataFields"
          :excludeFields.sync="adhocPivotExcludeFields"
          :filterFields.sync="adhocPivotFilterFields"
          :filteredHeaders="filteredHeaders"
        ></pivotOptions> -->
      </template>
    </BaseServerSyncDataTable>
    <!-- <v-row justify="end">
      <v-col sm="auto">
        <v-btn @click="downloadAdhoc" color="primary">
          <v-icon left>mdi-file-chart</v-icon>Generate Report
        </v-btn>
      </v-col>
    </v-row> -->
    <!-- //templateTodo: uncomment if this report will use saved queries -->
    <!-- <SavedQueries
        reportName="DOR_AuditReturnTransaction"
        :searchParams="adhocParameters"
        @selectQuery="selectQuery"
      /> -->
  </BasePanelWithHeader>
</template>

<script>
import pivotOptions from '@components/reports/pivotOptions'
//templateTodo: uncomment if this report will use saved queries
//import SavedQueries from '@components/reports/savedQueries'
import DOR_AuditReturnTransactionParams from '@components/reports/dor_interface/dOR_AuditReturnTransaction-adhoc-params'
import {get, sync, commit, call} from 'vuex-pathify'
import {debounce, range} from 'lodash'
//templateTODO: select default sortBy field
var defaultSortField = 'AuditReturnID'
export default {
  name: 'DOR_AuditReturnTransactionReport',
  components: {
    pivotOptions,
    DOR_AuditReturnTransactionParams,
    //templateTodo: uncomment if this report will use saved queries
    //SavedQueries
  },
  data: () => ({
    headers: [
      {
        text: 'Audit Return ID',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'AuditReturnID',
        visible: false,
      },
      {
        text: 'Registrant ID',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'RegistrantID',
        visible: true,
      },
      {
        text: 'License Number',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'LicenseNumber',
        visible: true,
      },
      {
        text: 'Entity Name',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'EntityName',
        visible: true,
      },
      {
        text: 'Amended Return',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'AmendedReturn',
        visible: true,
      },
      {
        text: 'Project Number',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ProjectNumber',
        visible: true,
      },
      {
        text: 'Audit Status',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'AuditStatus',
        visible: false,
      },
      {
        text: 'Report File Location',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ReportFileLocation',
        visible: false,
      },
      {
        text: 'Retrieved',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'Retrieved',
        visible: false,
      },
      {
        text: 'Activity Date',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ActivityDate',
        visible: false,
      },
    ],
  }),
  created() {
    this.adhocSelectedFields = this.headers
      .filter((h) => h.visible)
      .map((h) => h.value)
    this.displayAdhocDOR_AuditReturnTransactions()
  },
  watch: {
    adhocPivotIncludePivot(nval, oval) {
      if (nval) {
        this.adhocGroupBy = null
        this.adhocGroupDescending = null
      }
    },
    adhocSelectedFields: {
      handler(nval, oval) {
        this.headers.forEach((item) => {
          if (nval.includes(item.value)) {
            item.visible = true
          } else {
            item.visible = false
          }
        })
        if (nval != oval) {
          this.adhocQueryIsModified = true
        }
      },
      deep: true,
    },
  },
  computed: {
    ...get('dorAuditReturnTransaction', [
      'adhocDOR_AuditReturnTransactions',
      'adhocLoading',
      'adhocParameters',
    ]),
    ...sync('dorAuditReturnTransaction', [
      'adhocTotal',
      'adhocPage',
      'adhocSortBy',
      'adhocDescending',
      'adhocGroupBy',
      'adhocGroupDescending',
      'adhocRowsPerPage',
      'adhocPivotIncludePivot',
      'adhocPivotFirst',
      'adhocPivotName',
      'adhocPivotRowFields',
      'adhocPivotColumnFields',
      'adhocPivotExcludeFields',
      'adhocPivotDataFields',
      'adhocPivotFilterFields',
      'adhocSelectedFields',
      'adhocQueryIsModified',
      'adhocQueryName',
      'adhocText',
      //templateTODO: uncomment as used in AdHocDOR_AuditReturnTransactionReportParams (if using saved queries)
      // 'adhocAuditReturnID',
      // 'adhocRegistrantID',
      // 'adhocLicenseNumber',
      // 'adhocProjectNumber',
      // 'adhocAuditStatus',
      // 'adhocAmendedReturn',
      // 'adhocReportFileLocation',
      // 'adhocRetrieved',
    ]),
    //this can be used for filtering them down
    filteredHeaders: {
      get() {
        let list = this.headers.filter((h) => h.visible)
        list.sort((a, b) => {
          return (
            this.adhocSelectedFields.indexOf(a.value) -
            this.adhocSelectedFields.indexOf(b.value)
          )
        })
        return list
      },
    },
    showGroupBy() {
      return this.headers.filter((x) => x.groupable).length > 0
    },
    groupBy: {
      get() {
        if (this.adhocGroupBy) {
          return [this.adhocGroupBy]
        } else {
          return []
        }
      },
      set(nval) {
        if (nval) {
          if (Array.isArray(nval)) {
            if (nval.length > 0) {
              this.adhocGroupBy = nval[0]
            } else {
              this.adhocGroupBy = null
            }
          } else {
            this.adhocGroupBy = nval
          }
        } else {
          this.adhocGroupBy = null
        }
      },
    },
  },
  methods: {
    ...call('dorAuditReturnTransaction', [
      'displayAdhocDOR_AuditReturnTransactions',
      'downloadAdhoc',
    ]),
    selectQuery(item) {
      if (item && item.Query) {
        let query = JSON.parse(item.Query)
        if (Array.isArray(query.sortDirection)) {
          var descending = query.sortDirection.map((x) =>
            x == 'desc' ? true : false
          )
        } else {
          descending = query.sortDirection == 'desc' ? true : false
        }
        this.adhocDescending = descending
        this.adhocSortBy = query.sortProperty
        this.adhocGroupDescending =
          query.groupByDirection == 'desc' ? true : false
        this.adhocGroupBy = query.groupByProperty
        this.adhocText = query.searchText || ''
        this.adhocQueryName = query.queryName || item.Title
        this.adhocSelectedFields = query.includeFields || []
        if (!query.pivotOptions) {
          this.adhocPivotIncludePivot = query.includePivot
          this.adhocPivotFirst = query.pivotFirst
          this.adhocPivotName = query.pivotName
          this.adhocPivotRowFields = query.rowFields || []
          this.adhocPivotColumnFields = query.columnFields || []
          this.adhocPivotExcludeFields = query.excludeFields || []
          this.adhocPivotDataFields = query.dataFields || []
          this.adhocPivotFilterFields = query.filterFields || []
        } else {
          this.adhocPivotIncludePivot = query.pivotOptions.includePivot
          this.adhocPivotFirst = query.pivotOptions.pivotFirst
          this.adhocPivotName = query.pivotOptions.pivotName
          this.adhocPivotRowFields = query.pivotOptions.rowFields || []
          this.adhocPivotColumnFields = query.pivotOptions.columnFields || []
          this.adhocPivotExcludeFields = query.pivotOptions.excludeFields || []
          this.adhocPivotDataFields = query.pivotOptions.dataFields || []
          this.adhocPivotFilterFields = query.pivotOptions.filterFields || []
        }
        // most likely these will change over time, and shouldn't be loaded as part of the query
        //  this.adhocStartDate = query.startDate
        //  this.adhocEndDate = query.endDate
        //templateTODO: uncomment as used in AdHocDOR_AuditReturnTransactionReportParams (if using saved queries)
        // this.adhocAuditReturnID = query.auditReturnID || []
        // this.adhocRegistrantID = query.registrantID || []
        // this.adhocLicenseNumber = query.licenseNumber || []
        // this.adhocProjectNumber = query.projectNumber || []
        // this.adhocAuditStatus = query.auditStatus || []
        // this.adhocAmendedReturn = query.amendedReturn || []
        // this.adhocReportFileLocation = query.reportFileLocation || []
        // this.adhocRetrieved = query.retrieved || []
      } else {
        this.resetQuery()
      }

      if (item && item.ID <= 0) {
        //this can be used for additional processing for query presets that are hardcoded in javascript,
      }
      this.$nextTick(() => {
        this.queryIsModified = false
      })
    },
    resetQuery() {
      this.queryName = ''
      this.queryIsModified = false
    },
  },
}
</script>

<style lang="scss"></style>
