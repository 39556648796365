import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{attrs:{"sm":"12","md":"2"}},[_c('BaseDatePickerWithText',{attrs:{"label":"Start Date","clearable":""},model:{value:(_vm.adhocStartDate),callback:function ($$v) {_vm.adhocStartDate=$$v},expression:"adhocStartDate"}})],1),_c(VCol,{attrs:{"sm":"12","md":"2"}},[_c('BaseDatePickerWithText',{attrs:{"label":"End Date","clearable":""},model:{value:(_vm.adhocEndDate),callback:function ($$v) {_vm.adhocEndDate=$$v},expression:"adhocEndDate"}})],1),_c(VCol,{attrs:{"sm":"auto"}},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.downloadAdhoc}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-file-chart")]),_vm._v("Download ")],1),_c(VBtn,{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":_vm.emailAdhocDOR_InitiateAuditTransactions}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-file-chart")]),_vm._v("Email ")],1)],1),_c(VCol,{staticClass:"ml-auto",attrs:{"sm":"12","md":"2"}},[_c(VTextField,{attrs:{"prepend-inner-icon":"mdi-file-document-box-search","label":"Search","hint":"Registrant ID, License Number, Owner Name, Mailing Street 1, Mailing Street 2, Mailing City, Mailing State, Mailing Zip, Location Street 1, Location Street 2, Location City, Location State, Location Zip, Attn Line, Project Number, "},model:{value:(_vm.adhocText),callback:function ($$v) {_vm.adhocText=$$v},expression:"adhocText"}})],1),_c(VCol,{attrs:{"sm":"12","md":"3"}},[_c(VAutocomplete,{attrs:{"items":_vm.headers,"item-text":"text","item-value":"value","label":"Select Columns","chips":"","deletable-chips":"","small-chips":"","clearable":"","multiple":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 5)?_c(VChip,{attrs:{"small":"","close":""},on:{"click:close":function($event){return _vm.removeSelectedField(index)}}},[_vm._v(" "+_vm._s(item.text))]):_vm._e(),(index === 5)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.adhocSelectedFields.length - 5)+" others)")]):_vm._e()]}},{key:"prepend",fn:function(){return [_c('reorderSelectedFields',{model:{value:(_vm.adhocSelectedFields),callback:function ($$v) {_vm.adhocSelectedFields=$$v},expression:"adhocSelectedFields"}})]},proxy:true}]),model:{value:(_vm.adhocSelectedFields),callback:function ($$v) {_vm.adhocSelectedFields=$$v},expression:"adhocSelectedFields"}})],1)],1),(_vm.filters)?_c(VRow):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }