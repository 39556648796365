<template>
  <div>
    <v-row justify="end">
      <!-- <v-col sm="12" md="2">
        <BaseDatePickerWithText
          label="Start Date"
          clearable
          v-model="adhocStartDate"
        >
        </BaseDatePickerWithText>
      </v-col>
      <v-col sm="12" md="2">
        <BaseDatePickerWithText
          label="End Date"
          clearable
          v-model="adhocEndDate"
        >
        </BaseDatePickerWithText>
      </v-col> -->
      <v-col class="ml-auto" sm="12" md="2">
        <v-text-field
          v-model="adhocText"
          prepend-inner-icon="mdi-file-document-box-search"
          label="Search"
          hint="Registrant ID, License Number, Project Number, Audit Status, Amended Return, Report File Location, "
        ></v-text-field>
      </v-col>
      <v-col sm="12" md="3">
        <v-autocomplete
          v-model="adhocSelectedFields"
          :items="headers"
          item-text="text"
          item-value="value"
          label="Select Columns"
          chips
          deletable-chips
          small-chips
          clearable
          multiple
          dense
        >
          <template v-slot:selection="{item, index}">
            <v-chip
              small
              v-if="index < 5"
              close
              @click:close="removeSelectedField(index)"
            >
              {{ item.text }}</v-chip
            >
            <span v-if="index === 5" class="grey--text caption"
              >(+{{ adhocSelectedFields.length - 5 }} others)</span
            >
          </template>
          <template v-slot:prepend>
            <reorderSelectedFields v-model="adhocSelectedFields">
            </reorderSelectedFields>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <!-- <v-row justify="center">
      <v-col sm="12" md="2">
        <h4 @click="toggleFilters" class="pointer primary--text"
          >{{ filterText }}
        </h4>
      </v-col>
    </v-row> -->
    <v-row v-if="filters">
      <!-- //templateTODO: The Description and ID (item-text and item-value) 
          will need to be adjusted based on the properties in the array/lookup table
          these availableLists should be pulled from lookup tables and potentially
          filtered with a computed as desired (for IsActive status etc)
          These can also be switched to v-autocompletes if desired-->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocAuditReturnID" 
                  :items="availableAuditReturnID"
                  item-text="Description" 
                  item-value="ID" 
                  label="Audit Return ID"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- or via a multi-id templated lookup (you will need to import/rename and add to your components list) -->
      <!-- <v-col sm="12" md="3">
          <AuditReturnID
              v-model="adhocAuditReturnID"
              small-chips
              dense
          ></AuditReturnID>
              </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocRegistrantID" 
                  :items="availableRegistrantID"
                  label="Registrant ID"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocLicenseNumber" 
                  :items="availableLicenseNumber"
                  label="License Number"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocProjectNumber" 
                  :items="availableProjectNumber"
                  label="Project Number"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocAuditStatus" 
                  :items="availableAuditStatus"
                  label="Audit Status"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocAmendedReturn" 
                  :items="availableAmendedReturn"
                  label="Amended Return"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocReportFileLocation" 
                  :items="availableReportFileLocation"
                  label="Report File Location"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocRetrieved" 
                  :items="['true', 'false']"
                  label="Retrieved"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
    </v-row>
  </div>
</template>

<script>
import reorderSelectedFields from '@components/reports/reorderSelectedFields'
import {get, sync, commit, call} from 'vuex-pathify'
export default {
  name: 'DOR_AuditReturnTransactionReport',
  components: {
    reorderSelectedFields,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    filters: true,
    filterText: '-Hide Filters-',
  }),
  created() {},
  watch: {},
  computed: {
    ...sync('dorInitiateAuditTransaction', [
      'adhocStartDate',
      'adhocEndDate',
    ]),
    ...sync('dorAuditReturnTransaction', [
      'adhocText',
      'adhocSelectedFields',
      // 'adhocStartDate',
      // 'adhocEndDate',
      //templateTODO: uncomment as used in AdHocDOR_AuditReturnTransactionReportParams
      // 'adhocAuditReturnID',
      // 'adhocRegistrantID',
      // 'adhocLicenseNumber',
      // 'adhocProjectNumber',
      // 'adhocAuditStatus',
      // 'adhocAmendedReturn',
      // 'adhocReportFileLocation',
      // 'adhocRetrieved',
    ]),
    //templateTODO: custom available lookup computed properties should go here
  },
  methods: {
    toggleFilters() {
      this.filters = !this.filters
      if (this.filters) {
        this.filterText = '-Hide Filters-'
      } else {
        this.filterText = '-Show Filters-'
      }
    },
    removeSelectedField(index) {
      var list = this.adhocSelectedFields.filter((i) => true)
      list.splice(index, 1)
      this.adhocSelectedFields = list
    },
  },
}
</script>

<style lang="scss"></style>
